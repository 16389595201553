import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive'; // For dynamic breakpoints
import { IContentBlockViewProps } from './custom-content-block';

type ImageBreakpoint = 'sm' | 'md' | 'lg';

const ContentBlockView: React.FC<IContentBlockViewProps> = props => {
    const {
        contentBlockContainer,
        imageContainer,
        image_sm,
        image_md,
        image_lg,
        breakpoint_sm = 768,
        breakpoint_lg = 1200,
        contentBlockAnchorTag,
        imageLink,
        imageAriaLabel
    } = props;

    const imageBreakpointMap: Record<ImageBreakpoint, React.ReactNode | null> = {
        sm: image_sm,
        md: image_md,
        lg: image_lg
    };

    // Use react-responsive to determine the current breakpoint
    const isLarge = useMediaQuery({ query: `(min-width: ${breakpoint_lg}px)` });
    const isMedium = useMediaQuery({
        query: `(max-width: ${breakpoint_lg - 1}px) and (min-width: ${breakpoint_sm}px)`
    });

    // Add state to track the current breakpoint
    const [currentBreakpoint, setCurrentBreakpoint] = React.useState<ImageBreakpoint>('sm');

    // Determine the current breakpoint dynamically
    const getCurrentBreakpoint = (): ImageBreakpoint => {
        if (isLarge) {
            return 'lg';
        }
        if (isMedium) {
            return 'md';
        }
        return 'sm';
    };

    // Recalculate the breakpoint after component mounts or updates
    React.useEffect(() => {
        setCurrentBreakpoint(getCurrentBreakpoint());
    }, [isLarge, isMedium]);

    // Helper function to get the correct image for the breakpoint
    const getImage = (breakpoint: ImageBreakpoint): React.ReactNode | null => {
        console.log(`Current breakpoint: ${breakpoint}`);
        const image = imageBreakpointMap[breakpoint] || imageBreakpointMap.lg || imageBreakpointMap.md || imageBreakpointMap.sm || null;
        console.log(`Image selected for breakpoint '${breakpoint}':`, image);
        return image;
    };

    if (!image_sm && !image_md && !image_lg) {
        console.warn('No images provided for ContentBlockView');
        return null;
    }

    return (
        <Module {...contentBlockContainer}>
            {imageLink ? (
                <Node
                    {...contentBlockAnchorTag}
                    href={imageLink}
                    className={contentBlockAnchorTag ? contentBlockAnchorTag.className : ''}
                    aria-label={imageAriaLabel}
                >
                    <Node {...imageContainer}>{getImage(currentBreakpoint)}</Node>
                </Node>
            ) : (
                <Node {...imageContainer}>{getImage(currentBreakpoint)}</Node>
            )}
        </Module>
    );
};

export default ContentBlockView;
